import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { usersApi } from './services/users';
import { authApi } from './services/auth';
import user from './slices/user';
import bookingSummarySlice from './slices/bookingSummary';
import { filterApi } from './services/filterApi';

export const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [filterApi.reducerPath]: filterApi.reducer,
    user,
    bookingSummarySlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([usersApi.middleware, authApi.middleware]),
});

setupListeners(store.dispatch);
