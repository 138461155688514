import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Layout = styled(layout)`
  min-height: 100vh;
  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}
`;

export default Layout;
