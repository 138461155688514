import Icon from '@ant-design/icons';

const ErrorCloseSVG = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.55008 1.66663H13.4501L18.3334 6.54996V13.45L13.4501 18.3333H6.55008L1.66675 13.45V6.54996L6.55008 1.66663Z'
      stroke='#F93232'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.5 7.5L7.5 12.5'
      stroke='#F93232'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.5 7.5L12.5 12.5'
      stroke='#F93232'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ErrorCloseIcon = props => <Icon component={ErrorCloseSVG} {...props} />;

export default ErrorCloseIcon;
