import React from 'react';
import { useSelector } from 'react-redux';
import { protectedRouts } from 'constants/routes';
import { getUser } from './app/services/selectorHelpers';
import DashboardWrapper from './components/templates/DashboardWrapper';

// eslint-disable-next-line import/no-anonymous-default-export
const lazyLoadPage = path => React.lazy(() => import(`./${path}`));

const PageContainer = ({ route, isGlobal }) => {
  const user = useSelector(getUser);
  const Children = lazyLoadPage(route.component);
  return user && !isGlobal ? (
    <DashboardWrapper routeData={route}>
      <Children />
    </DashboardWrapper>
  ) : (
    <Children />
  );
};

export default PageContainer;

export const getPageComponents = role => {
  const components = {};
  Object.values(protectedRouts[role]).forEach(route => {
    components[route.key] = {
      component: route.component,
      pathname: route.pathname,
      redirectPath: route.redirectPath,
      pageTitle: route.pageTitle,
      showBackArrow: route.showBackArrow,
      key: route.key,
    };

    if (route.items) {
      Object.values(route.items).forEach(childRoute => {
        components[childRoute.key] = {
          component: childRoute.component,
          pathname: childRoute.pathname,
          redirectPath: route.redirectPath,
          pageTitle: route.pageTitle,
          key: route.key,
          subKey: route.subKey,
        };
      });
    }
  });

  return components;
};
