import Icon from '@ant-design/icons';

const LogOutDocSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.39837 2.09868C2.26551 2.16592 2.16388 2.2646 2.10184 2.38129L2 2.56065V10.2774C2 17.7428 2.00449 17.9985 2.08408 18.1824C2.14163 18.308 2.22571 18.4021 2.36286 18.4785C7.38142 21.3541 8.4657 21.9688 8.5853 21.9913C8.79326 22.0362 9.09876 21.9016 9.24917 21.7042V21.7042C9.32681 21.6022 9.36939 21.4779 9.37054 21.3498L9.38203 20.0756L9.39529 18.604L12.3471 18.5906C15.2326 18.5771 15.3034 18.5771 15.4539 18.483C15.5379 18.4336 15.653 18.326 15.7061 18.2408V18.2408C15.7724 18.1443 15.8079 18.0299 15.8079 17.9129V15.6566V13.216H15.122H14.4361V15.1901V17.1641H11.9135H9.3908L9.38203 11.5204L9.36919 6.05327C9.36891 5.93573 9.32989 5.82156 9.25815 5.72845V5.72845C9.17856 5.62521 8.61652 5.27538 7.35509 4.55294C6.36816 3.98318 5.53183 3.50322 5.49632 3.48066C5.45653 3.45377 7.20469 3.43577 9.93529 3.43577H14.4361V5.9703V8.50524H15.122H15.8079V5.50378V2.68412C15.8079 2.56848 15.7741 2.45537 15.7106 2.35874V2.35874C15.653 2.27805 15.5514 2.16592 15.4849 2.11648V2.11648C15.4045 2.0554 15.3064 2.02223 15.2054 2.02202L8.97917 2.0089L2.59735 2L2.39837 2.09868Z'
      fill='#2C5C86'
    />
    <path
      d='M17.4144 7.7157C17.3356 7.78414 17.2898 7.88296 17.2884 7.98733L17.2773 8.83721L17.264 9.85114H15.3965C14.2856 9.85114 13.4491 9.87349 13.3297 9.90059C12.8605 10.0082 12.5552 10.3897 12.5552 10.8607C12.5597 11.2646 12.7367 11.5606 13.0863 11.7491C13.2589 11.8432 13.312 11.8478 15.2636 11.8612L17.264 11.8747L17.2773 12.8841L17.2879 13.6963C17.2896 13.8248 17.3451 13.9468 17.4409 14.0325C17.5648 14.1446 17.6179 14.1626 17.7375 14.1446C17.9189 14.1131 21.8622 11.2284 21.9595 11.0536C22.0038 10.9638 22.0126 10.8965 21.9815 10.7664C21.9418 10.6094 21.8134 10.5061 19.9058 9.10202C18.0914 7.76948 17.8524 7.60812 17.7062 7.60812C17.5914 7.60812 17.4985 7.64391 17.4144 7.7157Z'
      fill='#2C5C86'
    />
  </svg>
);

const LogOut = props => <Icon component={LogOutDocSVG} {...props} />;

export default LogOut;
