import { CarryOutFilled, CustomerServiceFilled } from '@ant-design/icons';
import Coupon from 'assets/images/costum-icons/Coupon';
import ReviewIcon from 'assets/images/costum-icons/ReviewIcon';
import Dashboard from '../assets/images/costum-icons/Dashboard';
import User from '../assets/images/costum-icons/User';
import Booking from '../assets/images/costum-icons/Booking';
import Services from '../assets/images/costum-icons/Services';
import Invoice from '../assets/images/costum-icons/Invoice';
import Settings from '../assets/images/costum-icons/Settings';

export const protectedRouts = {
  authenticated: {
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      icon: <Dashboard />,
      pathname: '/dashboard',
      component: 'pages/dashboard-pages/admin/dashboard/dashboard.page',
    },
    users: {
      key: 'users',
      pageTitle: 'Users',
      icon: <User />,
      pathname: '/users',
      component: 'pages/dashboard-pages/admin/users/users.page',
    },
    customerView: {
      key: 'customerView',
      pageTitle: 'Custome View',
      icon: <User />,
      hideInSidebar: true,
      showBackArrow: true,
      pathname: '/customer-view/:id',
      component: 'pages/dashboard-pages/admin/users/customerView.page',
    },
    proView: {
      key: 'proView',
      pageTitle: 'Pro View',
      icon: <User />,
      hideInSidebar: true,
      showBackArrow: true,
      pathname: '/pro-view/:id',
      component: 'pages/dashboard-pages/admin/users/customerView.page',
    },
    booking: {
      key: 'booking',
      pageTitle: 'Booking',
      icon: <Booking />,
      pathname: '/booking',
      component: 'pages/dashboard-pages/admin/booking/booking.page',
    },
    bookingView: {
      key: 'bookingView',
      pageTitle: 'booking View',
      icon: <CarryOutFilled />,
      pathname: '/booking-view/:id',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/booking/bookingView.page',
    },
    services: {
      key: 'services',
      pageTitle: 'Services',
      icon: <Services />,
      pathname: '/services',
      component: 'pages/dashboard-pages/admin/services/services.page',
    },
    servicesAdd: {
      key: 'servicesAdd',
      pageTitle: 'Services Add',
      icon: <CustomerServiceFilled />,
      pathname: '/service-add',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/services/servicesadd.page',
    },
    servicesView: {
      key: 'servicesView',
      pageTitle: 'Services View',
      icon: <CustomerServiceFilled />,
      pathname: '/service-view/:id',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/services/serviceview.page',
    },
    servicesEdit: {
      key: 'serviceEdit',
      pageTitle: 'service Edit',
      icon: <CustomerServiceFilled />,
      pathname: '/service-edit/:id',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/services/serviceview.page',
    },
    invoices: {
      key: 'invoices',
      pageTitle: 'Invoices',
      icon: <Invoice />,
      pathname: '/invoices',
      component: 'pages/dashboard-pages/admin/invoice/invoices.page',
    },
    invoiceView: {
      key: 'invoiceView',
      pageTitle: 'Invoice View',
      icon: <CustomerServiceFilled />,
      pathname: '/invoice-view/:id',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/invoice/invoiceview.page',
    },
    coupon: {
      key: 'coupon',
      pageTitle: 'Coupon',
      icon: <Coupon />,
      pathname: '/coupon',
      component: 'pages/dashboard-pages/admin/coupon/Coupon.page',
    },
    couponAdd: {
      key: 'couponAdd',
      pageTitle: 'Coupon',
      pathname: '/coupon-add',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/coupon/CouponAddEdit.page',
    },
    couponEdit: {
      key: 'couponEdit',
      pageTitle: 'Coupon',
      pathname: '/coupon/:id',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/coupon/CouponAddEdit.page',
    },
    settings: {
      key: 'settings',
      pageTitle: 'Settings',
      icon: <Settings />,
      pathname: '/settings',
      hideInSidebar: true,
      component: 'pages/dashboard-pages/admin/settings/settings.page',
    },
    reviews: {
      key: 'reviews',
      pageTitle: 'Reviews',
      icon: <ReviewIcon />,
      hideInSidebar: true,
      pathname: '/reviews',
      component: 'pages/dashboard-pages/admin/reviews/Reviews.page',
    },
    reviewsAdd: {
      key: 'reviewsAdd',
      pageTitle: 'Reviews',
      pathname: '/reviews-add',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/reviews/ReviewAddEdit.page',
    },
    reviewsEdit: {
      key: 'reviewsEdit',
      pageTitle: 'Reviews',
      pathname: '/reviews/:id',
      hideInSidebar: true,
      showBackArrow: true,
      component: 'pages/dashboard-pages/admin/reviews/ReviewAddEdit.page',
    },
  },
};

const routes = {
  notFound: {
    pathname: '/404',
    key: 'not_found',
    pageTitle: '404',
  },
  signIn: {
    pathname: '/sign-in',
    key: 'sign-in',
    component: 'pages/auth-pages/sign-in.page',
    pageTitle: 'Sign In',
  },
  resetPassword: {
    pathname: '/reset-password',
    key: 'reset-password',
    component: 'pages/auth-pages/reset-password.page',
    pageTitle: 'Reset password',
  },
  forgetPassword: {
    pathname: '/forget-password',
    key: 'forget-password',
    component: 'pages/auth-pages/forget-password.page',
    pageTitle: 'Forget password',
  },
};

export const globalRoutes = {
  resetPassword: {
    pathname: '/reset-password',
    key: 'reset-password',
    component: 'pages/auth-pages/reset-password.page',
    pageTitle: 'Reset password',
  },
};

export const roleDefPaths = {
  authenticated: '/dashboard',
};
export default routes;
