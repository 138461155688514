/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { emptyState, loadState, saveState } from '../../configs/localStorage';

const initialState = { service_id: null, status: null };

const bookingSummarySlice = createSlice({
  name: 'bookingSummary',
  initialState,
  reducers: {
    setBookingSummary(state, { payload }) {
      state.service_id = payload?.service_id;
      state.status = payload?.status;
      saveState(payload);
    },
    removeBookingSummary(state, payload) {
      state.service_id = null;
      state.status = null;
    },
  },
});

export const { setBookingSummary, removeBookingSummary } = bookingSummarySlice.actions;
export default bookingSummarySlice.reducer;
