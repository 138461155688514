import styled, { css } from 'styled-components';
import { Select as select } from 'antd';

const Select = styled(select)`
  width: 100%;
  .ant-select-selector {
    background: #fff !important;
    border: 1px solid #eeeeee !important;
    box-sizing: border-box;
    //border-radius: 50px !important;
    //height: 44px !important;
    height: auto;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    padding: 12px 15px !important;
    display: flex;
    align-items: center;
  }
  &.ant-select-multiple {
    .ant-select-selection-item {
      background-color: #00aee6;
      color: #ffffff;
      border-radius: 6px;
      border: none;
    }
  }
  &:hover {
    border-color: #886cc0;
  }
  &:focus,
  &.ant-input-focused {
    border-color: #886cc0;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #886cc02e;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #00000096;
  }
  ${props =>
    props.type === 'blue' &&
    css`
      .ant-select-selector {
        border: 1px solid #000080 !important;
      }
      .ant-select-arrow {
        color: #000080 !important;
      }
    `}
  ${props =>
    props.mode === 'multiple' &&
    css`
      .ant-select-selector {
        padding: 0 5px !important;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      .ant-select-selector {
        padding: ${props.padding} !important;
      }
    `}
  ${props =>
    props.shadow &&
    css`
      .ant-select-selector {
        box-shadow: ${props.shadow} !important;
      }
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width}!important;
    `}
  ${props =>
    props.height &&
    css`
      .ant-select-selector {
        height: ${props.height}!important;
      }
    `}
    ${props =>
    props.min_height &&
    css`
      &.ant-select-selector {
        min-height: ${props.min_height}!important;
      }
    `}
    ${props =>
    props.max_height &&
    css`
      &.ant-select-selector {
        max-height: ${props.max_height}!important;
      }
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.back_color &&
    css`
      .ant-select-selector {
        background-color: ${props.back_color}!important;
      }
    `}

    ${props =>
    props.text_align &&
    css`
      .ant-select-selector {
        text-align: ${props.text_align}!important;
      }
    `}
  ${props =>
    props.font_size &&
    css`
      font-size: ${props.font_size} !important;
    `}
  ${props =>
    props.fw &&
    css`
      .ant-select-selector {
        font-weight: ${props.fw} !important;
      }
    `}
  ${props =>
    props.color &&
    css`
      .ant-select-selector {
        color: ${props.color} !important;
      }
    `}
  ${props =>
    props.font_size &&
    css`
      .ant-select-selection-item {
        font-size: ${props.font_size} !important;
      }
    `}
  ${props =>
    props.fw &&
    css`
      .ant-select-selection-item {
        font-weight: ${props.fw} !important;
      }
    `}
  ${props =>
    props.color &&
    css`
      .ant-select-selection-item {
        color: ${props.color} !important;
      }
    `}
`;

export default Select;
