import { memo } from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Col, Header, Paragraph, Row } from '../atoms';

const HeaderContent = styled.div`
  width: 100%;
  padding: 24px 25px;
  border-bottom: 1px solid #eeeeee;
`;

const DashboardHeader = ({ routeData, setCollapsed }) => {
  const navigate = useNavigate();

  return (
    <Header padding='0' back_color='white'>
      <HeaderContent>
        <Row align='middle' justify={'space-between'}>
          <Col>
            <Row align='middle'>
              {/* <MenuOutlined
                style={{ fontSize: '22px', marginRight: '10px' }}
                onClick={() => setCollapsed(prev => !prev)}
              /> */}
              {routeData.showBackArrow ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowLeftOutlined
                    onClick={() => navigate(-1)}
                    style={{ width: '40px', color: 'black', fontSize: '22px' }}
                  />
                  <Paragraph mb={0} fz={24} fw={500} padding={'0px 0px 0px 0px'}>
                    Go Back
                  </Paragraph>
                </div>
              ) : (
                <Paragraph mb={0} fz={24} fw={500}>
                  {routeData?.pageTitle || 'Dashboard'}
                </Paragraph>
              )}
            </Row>
          </Col>
        </Row>
      </HeaderContent>
    </Header>
  );
};

export default memo(DashboardHeader);
