import styled, { css } from 'styled-components';
import { Menu as menu } from 'antd';

const Menu = styled(menu)`
  ${props =>
    props.type === 'dashboard' &&
    css`
      border-right: 0;
      padding-bottom: 30px;
      background: white !important;
      .ant-menu-item {
        color: #717579;
        background-color: white !important;
        max-width: 100%;
        font-size: 18px;

        &:hover {
          color: #2c5c86;
          path {
            fill: #2c5c86;
          }
        }

        .case_icon svg {
          width: 18px;
        }
        .ant-menu-item-icon {
          width: 24px;
        }

        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          .ant-menu-title-content {
            color: white !important;
          }
          background-color: #00aee6 !important;
          width: 100%;
          color: red !important;
          font-weight: 700;

          :after {
            left: 0;
            right: unset;
            border-radius: 0 57px 57px 0;
            border-right: 6px solid #2c5c86;
            transform: scaleY(1);
            opacity: 1;
            border-radius: 0px 57px 57px 0px;
          }

          path {
            fill: #ffffff;
          }
        }
      }

      .ant-menu-sub {
        background-color: #fff;

        .ant-menu-item {
          //padding-left: 65px !important;
        }
      }
      .ant-menu-title-content {
        color: black !important;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        font-weight: 500;
        font-size: 16px;
        svg {
          font-size: 18px;
        }
      }
      .ant-menu-item:active,
      .ant-menu-submenu-title:active {
        background-color: #fff;
      }
      .ant-menu-submenu {
        color: #717579 !important;
        font-weight: 500 !important;
      }
      .ant-menu-submenu-title:hover {
        color: #2c5c86 !important;

        path {
          fill: #2c5c86;
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-arrow {
          color: #717579 !important;
        }
        &:hover {
          .ant-menu-submenu-title > .ant-menu-submenu-arrow {
            color: #886cc0 !important;
            path {
              fill: #886cc0;
            }
          }
        }
      }
    `}

  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #00aee6;
  }
  & .ant-menu-item-selected {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
    path {
      fill: #ffffff;
      stroke: none;
    }
  }

  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0px 0px 0px 30px;
    display: flex;
    align-items: center;

    .ant-menu-item-icon {
      display: flex;
      align-items: center;
      min-width: 24px;
    }
  }

  ${props =>
    props.type === 'dashboard' &&
    props.sectype === 'collapsed' &&
    css`
      .ant-menu-item {
        background: white !important;
        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          width: 100%;
          border-radius: 0;
          color: #ffffff !important;
        }
      }
      .ant-menu-vertical {
        position: absolute;
        top: 0;
        /* right: 0; */
        left: 0;
        bottom: 0;
        border-right: 5px solid red;
        opacity: 0;
        transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        content: '';
      }
    `}
`;

export default Menu;
