import styled, { css } from 'styled-components';
import { Switch as toggle } from 'antd';

const Switch = styled(toggle)`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color}!important;
    `}
  ${props =>
    props.switched_color &&
    css`
      &.ant-switch-checked {
        background-color: ${props.switched_color}!important;
        border: 1px solid #fff !important;
        top: -2px !important;
      }
    `}
    ${props =>
    props.switch_color &&
    props.switch_color_border &&
    css`
      background-color: ${props.switch_color}!important;
      border: ${props.switch_color_border} !important;
      top: -2px !important;
    `}
  
    ${props =>
    props.circle_color &&
    css`
      .ant-switch-handle {
        &:before {
          top: 0px;
          right: 2px;
          bottom: 2px;
          left: 0;
          background-color: #fff;
          border-radius: 9px;
          box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
          content: '';
          border: ${props.circle_color} !important;
        }
      }
    `}
`;

export default Switch;
