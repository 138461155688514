import React from 'react';
import SignInWrapper from 'components/organisms/guest/sign-in-wrapper';
import SignIn from '../../components/organisms/guest/sign-in';

const SignInPage = () => {
  return (
    <SignInWrapper>
      <SignIn />
    </SignInWrapper>
  );
};

export default SignInPage;
