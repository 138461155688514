import styled, { css } from 'styled-components';
import { InputNumber as inputNumber } from 'antd';

const InputNumber = styled(inputNumber)`
  background: transparent;
  border: 1px solid #edeff1;
  box-sizing: border-box;
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  padding: 0 15px;
  font-weight: bold !important;
  line-height: 19px;
  font-size: 16px;
  // width: 100% !important;
  &:hover {
    border-color: #886cc0;
    border: 1px solid transparent;
    background: transparent;
    outline: 0;
  }
  &:hover {
    // border-color: #eeeeee;
    // border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
  }
  &:focus,
  &.ant-input-focused {
    // border-color: #886cc0;
    // border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #886cc02e;
  }
  .ant-input-number-group-addon,
  ant-input-number-group-addon:hover {
    border: 1px solid transparent;
    background: transparent;
  }

  & .ant-input-number-in-form-item {
    border: none !important;
  }
  & .ant-input-number-input {
    border: none !important;
  }
  .ant-input-number-input-wrap {
    height: 100%;

    .ant-input-number-input {
      height: 100%;
      padding: 0;
    }
  }
  ${props =>
    props.font_size &&
    css`
      .ant-input-number-input {
        font-size: ${props.font_size} !important;
      }
    `}
  ${props =>
    props.fw &&
    css`
      .ant-input-number-input {
        font-weight: ${props.fw} !important;
      }
    `}
  ${props =>
    props.color &&
    css`
      .ant-input-number-input {
        color: ${props.color} !important;
      }
    `}

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }

  ${props =>
    props.color_type === 'blue' &&
    css`
      border: 1px solid #000080 !important;
      &.ant-input-number-disabled {
        color: #000080;
        background-color: #fff;
        border: 1px solid #000080 !important;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
      & .ant-input-number,
      & .ant-input-number-input-wrap,
      & input {
        cursor: ${props.cursor};
      }
    `}
    ${props =>
    props.input_number_border &&
    css`
      .ant-input-number {
        border: ${props.input_number_border}!important;
      }
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.text_align &&
    css`
      input {
        text-align: ${props.text_align};
      }
    `}
 

  ${props =>
    props.hide === 'controls' &&
    css`
      padding: 0 10px;
      .ant-input-number-handler-wrap {
        display: none;
      }
    `}
`;

export default InputNumber;
