import Icon from '@ant-design/icons';

const ServicesDocSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.465 0.990002L13.365 1.815L13.74 3.63C13.5937 3.77625 13.4381 3.945 13.275 4.125L11.475 3.72L10.59 5.79L12.12 6.81C12.1031 7.06313 12.1031 7.32188 12.12 7.575L10.545 8.535L11.37 10.635L13.14 10.275C13.2993 10.4663 13.4737 10.6406 13.665 10.8L13.26 12.57L15.345 13.455L16.365 11.925C16.62 11.9419 16.86 11.9419 17.115 11.925L18.09 13.5L20.19 12.675L19.785 10.755C19.9743 10.5975 20.1375 10.4175 20.295 10.23L22.125 10.695L23.025 8.595L21.435 7.62C21.45 7.37063 21.45 7.13438 21.435 6.885L23.01 5.91L22.185 3.81L20.355 4.215C20.1975 4.02563 20.0193 3.8475 19.83 3.69L20.235 1.92L18.15 1.035L17.175 2.565C16.9237 2.54063 16.6706 2.5275 16.425 2.55L15.465 0.990002ZM16.8 5.28C17.8612 5.28 18.72 6.13875 18.72 7.2C18.72 8.26125 17.8612 9.12 16.8 9.12C15.7387 9.12 14.88 8.26125 14.88 7.2C14.88 6.13875 15.7387 5.28 16.8 5.28ZM6.49496 9.6L6.19496 11.52C5.93059 11.6044 5.66246 11.7244 5.39996 11.865L3.79496 10.695L2.11496 12.375L3.22496 13.995C3.09184 14.2575 2.98684 14.5219 2.89496 14.79L0.959961 15.15V17.49L2.87996 17.835C2.96434 18.1013 3.08246 18.3806 3.22496 18.645L2.05496 20.205L3.73496 21.885L5.35496 20.775C5.61559 20.9081 5.88371 21.0131 6.14996 21.105L6.44996 23.04H8.80496L9.14996 21.12C9.41809 21.0356 9.69559 20.9175 9.95996 20.775L11.565 21.945L13.245 20.265L12.09 18.645C12.2212 18.3844 12.3131 18.1163 12.405 17.85L14.4 17.49V15.135L12.435 14.835C12.3506 14.5706 12.2306 14.3006 12.09 14.04L13.245 12.435L11.58 10.695L9.94496 11.865C9.68809 11.7338 9.42934 11.6269 9.16496 11.535L8.86496 9.6H6.49496ZM7.67996 14.4C8.73559 14.4 9.59996 15.2644 9.59996 16.32C9.59996 17.3756 8.73559 18.24 7.67996 18.24C6.62434 18.24 5.75996 17.3756 5.75996 16.32C5.75996 15.2644 6.62434 14.4 7.67996 14.4Z'
      fill='#2C5C86'
    />
  </svg>
);

const Services = props => <Icon component={ServicesDocSVG} {...props} />;

export default Services;
