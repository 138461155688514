import Icon from '@ant-design/icons';

const InvoiceDocSVG = () => (
  <svg width='24' height='24' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.7656 6.0344L10.5656 0.8344C10.4152 0.684 10.212 0.6 10 0.6H1.6C0.716 0.6 0 1.316 0 2.2V19.8C0 20.684 0.716 21.4 1.6 21.4H14.4C15.284 21.4 16 20.684 16 19.8V6.6C16 6.388 15.916 6.1848 15.7656 6.0344ZM10.4 15.8H4C3.5584 15.8 3.2 15.4416 3.2 15C3.2 14.5584 3.5584 14.2 4 14.2H10.4C10.8416 14.2 11.2 14.5584 11.2 15C11.2 15.4416 10.8416 15.8 10.4 15.8ZM12 12.6H4C3.5584 12.6 3.2 12.2416 3.2 11.8C3.2 11.3584 3.5584 11 4 11H12C12.4416 11 12.8 11.3584 12.8 11.8C12.8 12.2416 12.4416 12.6 12 12.6ZM10.4 7C9.9584 7 9.6 6.6416 9.6 6.2V2.1232L14.4768 7H10.4Z'
      fill='#2C5C86'
    />
  </svg>
);

const Invoice = props => <Icon component={InvoiceDocSVG} {...props} />;

export default Invoice;
