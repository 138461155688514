import styled, { css } from 'styled-components';
import { Form } from 'antd';

const FormItem = styled(Form.Item)`
  max-width: 100% !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-weight: 700 !important;
  padding: 0 !important;
  margin-bottom: 10px;

  .ant-form-item-label {
    padding: 0 0 3px 0 !important;
    max-height: 30px;
    margin-top: 5px;
  }

  .ant-form-item-control-input {
    min-height: 20px;
  }

  .ant-form-item-label > label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #717579;
    //margin-bottom: 8px;

    &:before {
      color: #717579 !important;
    }
  }
  ${props =>
    props.width &&
    css`
      width: ${props.width} !important;
    `}
  ${props =>
    props.requier_color &&
    css`
      .ant-form-item-label > label {
        font-weight: 400;
        font-size: 14px;
        color: #717579;
        &:before {
          color: ${props.requier_color} !important;
        }
      }
    `}

  &:focus-within .ant-form-item-label > label {
    // color: #00aee6;

    &:before {
      // color: #00aee6 !important;
    }
  }

  ${props =>
    (props.label_mb || props.label_mb === 0) &&
    css`
      .ant-form-item-label > label {
        margin-bottom: ${props.label_mb}px;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.fw &&
    css`
      .ant-form-item-label > label {
        font-weight: ${props.fw} !important;
      }
    `}
    ${props =>
    props.fz &&
    css`
      .ant-form-item-label > label {
        font-size: ${props.fz} !important;
      }
    `}
    ${props =>
    props.font_size &&
    css`
      .ant-form-item-label > label {
        font-weight: 700;
        font-size: ${props.font_size} !important;
        color: #717579;
      }
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
  ${props =>
    props.margin_right &&
    css`
      margin-right: ${props.margin_right}!important;
    `}
  ${props =>
    props.justify &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.justify}!important;
      }
    `}
  ${props =>
    props.layout === 'vertical' &&
    css`
      div {
        width: 100%;
        text-align: start;
      }
    `}
  ${props =>
    props.labelcolor &&
    css`
      .ant-form-item-label > label {
        color: ${props.labelcolor};
      }
    `}
  ${props =>
    props.labelalign &&
    css`
      .ant-form-item-label {
        text-align: ${props.labelalign};
      }
    `}
  ${props =>
    props.labelheight &&
    css`
      .ant-form-item-label {
        height: ${props.labelheight};
      }
    `}
  ${props =>
    props.label_col_align &&
    css`
      .ant-form-item-label {
        display: flex;
        align-items: ${props.label_col_align};
      }
    `}
  ${props =>
    props.hide === 'requiredText' &&
    css`
      .ant-form-item-explain {
        display: none;
      }
    `}
  ${props =>
    props.error_padding &&
    css`
      .ant-form-item-explain {
        padding: ${props.error_padding};
      }
    `}
  ${props =>
    props.display_item === 'none' &&
    css`
      .ant-form-item-control-input {
        display: ${props.display_item};
      }
    `}
  ${props =>
    props.show_only === 'validate' &&
    css`
      .ant-form-item-control-input,
      .ant-form-item-label {
        display: none;
      }
    `}
`;

FormItem.defaultProps = {};

/** @component */
export default FormItem;
