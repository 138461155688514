import { createCustomApi } from 'configs/rtk-query';

export const filterApi = createCustomApi({
  reducerPath: 'filterApi',
  endpoints: builder => ({
    filter: builder.mutation({
      query: type => ({
        url: `custom/bookingChart?type=${type}`,
      }),
    }),
  }),
});

export const { useFilterQuery } = filterApi;
