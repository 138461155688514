import React from 'react';
import { Image } from 'antd';
import Logo from 'assets/images/Logo.png';
import { Row, Col } from 'components/atoms/';
import Background from '../../../assets/images/signInBackground.png';

const SignInWrapper = ({ children }) => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      backgroundImage: `url(${Background})`,
      backgroundPosition: 'bottom',
      backgroundSize: '100% 67%',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <Row width='90%' justify='center' gutter={24}>
      <Col mb='48' justify='center' span={24}>
        <Image preview={false} src={Logo} style={{ marginTop: '-40px' }} />
      </Col>
      <Col justify='center' width='100%' align='inherit' span={24}>
        <Row height='fit-content'>{children}</Row>
      </Col>
    </Row>
  </div>
);

export default SignInWrapper;
