import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ResLogo1 from '../../assets/images/Logo.png';
import { getUserRole } from '../../app/services/selectorHelpers';
import { Layout, Menu, Content, Sider, Row } from '../atoms';
import { protectedRouts } from '../../constants/routes';
import DashboardHeader from '../molecules/DashboardHeader';
import LogOut from '../../assets/images/costum-icons/LogOut';
import { removeUser } from '../../app/slices/user';

const { SubMenu } = Menu;

const LogoWrapper = styled.div`
  width: 100%;
  padding: 30px 100px 0px 20px;
  img {
    width: 50px;
    display: block;
  }
  ${props =>
    props.type === 'collapsed' &&
    css`
      padding: 30px 0px 0px 20px;
      img {
        width: 50px;
        display: block;
      }
      ,
    `}
`;
const DashboardWrapper = ({ routeData, children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(getUserRole);
  const [collapsed, setCollapsed] = useState(true);
  const collapsedText = collapsed ? 'collapsed' : '';

  const handleLogout = () => {
    dispatch(removeUser());
    navigate('/sign-in');
  };
  return (
    <Layout background='white'>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme='light'
        width={250}
        collapsedWidth={80}
        back_color='white'
        shadow='1px 0 8px rgba(0, 0, 0, 0.05)'
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
      >
        <LogoWrapper type={collapsedText}>
          <img src={ResLogo1} alt='logo' />
        </LogoWrapper>

        <Menu
          mode='inline'
          selectedKeys={pathname}
          defaultOpenKeys={[(!collapsed && routeData?.subKey) || '']}
          type='dashboard'
          sectype={collapsedText}
        >
          {Object.values(protectedRouts[role]).map(menuitem =>
            menuitem.items ? (
              <SubMenu
                key={menuitem.key}
                icon={menuitem.icon}
                title={menuitem?.menuTitle || menuitem?.pageTitle}
              >
                {Object.values(menuitem.items).map(subItem => (
                  <Menu.Item
                    key={subItem.pathname}
                    onClick={() => {
                      navigate(subItem.pathname);
                    }}
                  >
                    {subItem.menuTitle || subItem.pageTitle}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              !menuitem.hideInSidebar && (
                <Menu.Item
                  key={menuitem.pathname}
                  icon={menuitem.icon}
                  onClick={() => {
                    setCollapsed(true);
                    navigate(menuitem.pathname);
                  }}
                >
                  {menuitem?.menuTitle || menuitem?.pageTitle}
                </Menu.Item>
              )
            )
          )}
        </Menu>
        {!collapsed ? (
          <Row style={{ position: 'absolute', bottom: '20px', left: '20px' }}>
            <LogOut onClick={handleLogout} />
            <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleLogout}>
              Log Out
            </span>
          </Row>
        ) : (
          <LogOut
            style={{ position: 'absolute', bottom: '20px', left: '20px' }}
            onClick={handleLogout}
          />
        )}
      </Sider>
      <Layout>
        <DashboardHeader routeData={routeData} setCollapsed={setCollapsed} />
        <Content back_color='white' padding={'15px 24px'}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardWrapper;
