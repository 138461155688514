import { notification } from 'antd';
import { WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ErrorCloseIcon from 'assets/images/costum-icons/ErrorClose';
import CheckIcon from 'assets/images/costum-icons/check';

export const openNotificationWithIcon = (type, text, btn, key, warningText) => {
  notification[type]({
    message: `${type.charAt(0).toUpperCase() + type.slice(1)} !`,
    description:
      text ||
      (type === 'info' && 'Notification message. Here will be information.') ||
      ((type === 'warning' || type === 'error') && warningText) ||
      (type === 'success' && 'you have successfully completed'),
    duration: false,
    className: { type },
    style: {
      width: 'auto',
    },
    icon:
      (type === 'info' && (
        <ExclamationCircleOutlined style={{ color: '#E5E5E5', fontSize: '20px' }} />
      )) ||
      (type === 'error' && <ErrorCloseIcon />) ||
      (type === 'success' && <CheckIcon />) ||
      (type === 'warning' && <WarningOutlined style={{ color: '#FFB82E', fontSize: '20px' }} />),
    btn,
    key,
  });
};
