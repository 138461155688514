import styled, { css } from 'styled-components';
import { Row as row } from 'antd';

const Row = styled(row)`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

    ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr} !important;
    `}

    ${props =>
    props.align_items &&
    css`
      align-items: ${props.align_items};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex}!important;
    `}
    ${props =>
    props.flex_direction &&
    css`
      flex-direction: ${props.flex_direction}!important;
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow}!important;
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color}!important;
    `}
  ${props =>
    props.border_bot &&
    css`
      border-bottom: ${props.border_bot};
    `}
  ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.odd_back &&
    css`
      &:nth-child(odd) {
        background-color: ${props.odd_back};
      }
    `}
    ${props =>
    props.type === 'not-found' &&
    css`
      background: #ffffff;
      box-shadow: 2px 2px 4px rgba(0, 19, 36, 0.5);
      border-radius: 16px;
      width: 48%;
    `}
`;

/** @component */
export default Row;
