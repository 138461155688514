import styled, { css } from 'styled-components';
import { Button as button } from 'antd';
import propTypes from 'prop-types';

const Button = styled(button)`
  background: #00aee6;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  border: none;
  font-weight: 500;
  color: #fff;
  //padding: 8px 16px;
  padding: 0 16px;
  font-size: 14px;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.back_color &&
    css`
      background: ${props.back_color}!important;
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow}!important;
    `}
  ${props =>
    props.icon_height &&
    css`
      svg {
        height: ${props.icon_height}!important;
      }
    `}
  ${props =>
    props.type === 'primary' &&
    css`
      background: #00aee6;
      &:hover {
        background: #00aee6c9;
        color: #fff;
      }
      &:active {
        background: #00aee6;
        color: #fff;
      }
      &:focus {
        background: #00aee6;
        color: #fff;
      }
      &[disabled] {
        background: #e7e7e7;
        color: #616161;
      }
    `}

    ${props =>
    props.type === 'accept' &&
    css`
      background: #00aee6;
      &:hover {
        background: #00aee6;
        color: #fff;
      }
      &:active {
        background: #00aee6;
        color: #fff;
      }
      &:focus {
        background: #00aee6;
        color: #fff;
      }
      &[disabled] {
        background: #e7e7e7;
        color: #616161;
      }
    `}
    ${props =>
    props.type === 'decline' &&
    css`
      background: #fff;
      border: #ff002e 1px solid;
      color: #ff002e;
      box-shadow: none;
      &:hover {
        background: #fff;
        color: #ff002e;
        border: #ff002e 1px solid;
      }
      &:active {
        background: #fff;
        color: #ff002e;
      }
      &:focus {
        background: #fff;
        color: #ff002e;
      }
      &[disabled] {
        background: #e7e7e7;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      background: #000080;

      &:hover {
        background: #0202a5;
        color: #fff;
      }

      &:active {
        background: #000080;
        color: #fff;
      }

      &:focus {
        background: #000080;
        color: #fff;
      }

      &[disabled] {
        background: #000080;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'grey' &&
    css`
      background: #c4c4c4;
      color: #fff;

      &:hover {
        background: #0202a5;
        color: #fff;
      }

      &:active {
        background: #c4c4c4;
        color: #fff;
      }

      &:focus {
        background: #c4c4c4;
        color: #fff;
      }

      &[disabled] {
        background: #c4c4c4;
        color: #616161;
      }
    `}
  ${props =>
    props.sectype === 'ghost' &&
    css`
      color: #886cc0;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #756399;
      }
      &:active {
        background: transparent;
        color: #756399;
      }
      &:focus {
        background: transparent;
        color: #756399;
      }
    `}
  ${props =>
    props.sectype === 'blue_ghost' &&
    css`
      color: #000080;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #000080;
      }
      &:active {
        background: transparent;
        color: #000080;
      }
      &:focus {
        background: transparent;
        color: #000080;
      }
    `}
  ${props =>
    props.sectype === 'secondary' &&
    css`
      color: #717579;
      &:hover {
        color: #717579;
      }
      &:active {
        color: #717579;
      }
      &:focus {
        color: #717579;
      }
    `}
  ${props =>
    props.type === 'outline' &&
    css`
      background: none;
      border: 1px solid #000080;
      color: #000080;
      &:hover {
        color: #000080;
        border-color: #000080;
      }
      &:active {
        color: #000080;
        border-color: #000080;
      }
      &:focus {
        color: #000080;
        border-color: #000080;
      }
    `}
  ${props =>
    props.type === 'blue_outline' &&
    css`
      background: none;
      border: 1px solid ${props.bordercolor};
      //border-radius: 50px;
      color: #886cc0;
      &:hover {
        color: #6138fb;
        border-color: #6138fb;
      }
      &:active {
        color: #886cc0;
        border-color: #886cc0;
      }
      &:focus {
        color: #886cc0;
        border-color: #886cc0;
      }
    `}

  ${props =>
    props.type === 'primitive' &&
    css`
      background: none;
      border: none;
      //border-radius: 50px;
      box-shadow: none;
      color: black;
      &:hover {
        color: black;
        border-color: white;
      }
      &:active {
        color: black;
        border-color: white;
      }
      &:focus {
        color: black;
        border-color: white;
      }
    `}
  ${props =>
    props.type === 'dark_outline' &&
    css`
      background: #fff;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      color: #171b1e;
      box-shadow: none;
      &:hover {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:active {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:focus {
        color: #171b1e;
        border-color: #171b1e;
      }
    `}
  ${props =>
    props.type === 'black_outline' &&
    css`
      background: #fff;
      border: 1px solid #171b1e;
      color: #171b1e;
      box-shadow: none;
      &:hover {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:active {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:focus {
        color: #171b1e;
        border-color: #171b1e;
      }
    `}
  ${props =>
    props.type === 'destructive' &&
    css`
      background: red;
      border: 1px solid #red;
      //border-radius: 10px;
      color: #fff;
      box-shadow: none;
      &:hover {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:active {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:focus {
        color: #ff4f46;
        border-color: #ff4f46;
      }
    `}
  ${props =>
    props.type === 'grey_destructive' &&
    css`
      border: 1px solid #c4c4c4;
      box-shadow: none;
      color: #ff2222;
      background: #c4c4c4;
      &:hover {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
      &:active {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
      &:focus {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
    `}
  ${props =>
    props.type === 'destructive_outline' &&
    css`
      background: #fff;
      border: 1px solid #f8817b;
      //border-radius: 10px;
      color: #f8817b;
      box-shadow: none;
      &:hover {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:active {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:focus {
        color: #ff4f46;
        border-color: #ff4f46;
      }
    `}
  ${props =>
    props.type === 'grey_ghost' &&
    css`
      color: #717579;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #717579;
      }
      &:active {
        background: transparent;
        color: #717579;
      }
      &:focus {
        background: transparent;
        color: #717579;
      }
    `}
    ${props =>
    props.type === 'signIn_ghost' &&
    css`
      color: #58585a;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #58585a;
      }
      &:active {
        background: transparent;
        color: #58585a;
      }
      &:focus {
        background: transparent;
        color: #58585a;
      }
    `}
  ${props =>
    props.type === 'dark_ghost' &&
    css`
      color: #171b1e;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #171b1e;
      }
      &:active {
        background: transparent;
        color: #171b1e;
      }
      &:focus {
        background: transparent;
        color: #171b1e;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}
      ${props =>
    props.left &&
    css`
      left: ${props.left};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
      ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
    ${props =>
    props.border_radius &&
    css`
      border-radius: ${props.border_radius}!important;
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display}!important;
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align}!important;
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify}!important;
    `}
  ${props =>
    props.line_height &&
    css`
      line-height: ${props.line_height}!important;
    `}
`;

Button.propTypes = {
  type: propTypes.string,
};

Button.defaultProps = {};

/** @component */
export default Button;
