import styled, { css } from 'styled-components';
import { Table as table } from 'antd';
import propTypes from 'prop-types';

const Table = styled(table)`
  //* {
  //  border-color: #969696 !important;
  //}
  .expanded-table-last {
    z-index: 2;
  }
  // .ant-spin-container{
  //   width:600px;
  // }
  .full-expanded > td {
    z-index: 1;
    display: none;
  }
  .ant-table {
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    //padding: 0px 0px 0px 10px;

    .docs_table_header {
      display: flex;
      justify-content: space-between;
    }

    .documents_status {
      .anticon {
        margin-right: 10px;

        &.anticon-check-circle {
          color: #00dace;
        }

        &.anticon-exclamation-circle {
          color: #fc5e7a;
        }

        &.anticon-close-circle {
          color: #fc5e7a;
        }

        &.anticon-clock-circle {
          color: #35a5e4;
        }
      }
    }

    .ant-table-cell {
      padding: 5px;
    }
  
  }
  & .table,
  & .thead,
  & .ant-table-container,
  & tr,
  & td,
  & th,
  & tbody{
    border-color: #EDEFF1 !important;
  }
  & .ant-table-container{
    border-left: none !important;
  }
 
  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: none !important;
  }

  .ant-table-title {
    border-bottom: 1px solid #e8eef4;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 7px 10px !important;
    }

    > tr > th {
      background-color: #fff;
      font-weight: 500;
      font-size: 12px;
      color: #171b1e;
      border-right: none !important;
    }
  }



    .ant-table-cell {
      padding: 5px 10px !important;
      border-right: none !important;
    }

    > tr {
      td {
        font-size: 14px;
        color: #717579;
        font-weight: 350;
      }

      //&:last-child {
      //  > td {
      //    border-bottom: 0;
      //  }
      //}
    }
    > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: unset;
    }
  }


${props =>
  props.isSummary &&
  css`
    .ant-table-tbody {
      .ant-table-row {
        td:first-child {
          color: #58585a;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    .ant-table-thead {
      tr {
        th:first-child {
          text-align: left !important;
        }
        th {
          text-align: center;
          &:before {
            background-color: #fff !important;
          }
        }
      }
    }
  `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.body_height &&
    css`
      .ant-table-tbody {
        height: ${props.body_height} !important;
      }
    `}
  ${props =>
    props.colswidth &&
    css`
      th {
        width: ${props.colswidth};
      }
    `}
  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${props =>
    props.row &&
    css`
      .ant-table-cell {
        width: ${props.row};
      }
    `}
  ${props =>
    props.headcolor &&
    css`
      .ant-table-thead > tr > th {
        color: ${props.headcolor};
      }
    `}
  ${props =>
    props.headerbackcolor &&
    css`
      .ant-table-thead > tr > th {
        background-color: ${props.headerbackcolor};
      }
    `}
  ${props =>
    props.footerbackcolor &&
    css`
      .ant-table-footer {
        background-color: ${props.footerbackcolor};
      }
    `}
  ${props =>
    props.expand_back &&
    css`
      & .ant-table-cell {
        background-color: ${props.expand_back} !important;
      }
    `}
  ${props =>
    props.footertopborder &&
    css`
      .ant-table-footer {
        border-top: ${props.footertopborder} !important;
      }
    `}
  ${props =>
    props.footerpadding &&
    css`
      .ant-table-footer {
        padding: ${props.footerpadding} !important;
      }
    `}
  ${props =>
    props.cell_padding &&
    css`
      .ant-table-cell {
        padding: ${props.cell_padding} !important;
      }
    `}
  ${props =>
    props.bodycolor &&
    css`
      .ant-table-tbody > tr td {
        color: ${props.bodycolor};
      }
    `}
  ${props =>
    props.bodyweight &&
    css`
      .ant-table-tbody > tr td {
        font-weight: ${props.bodyweight};
      }
    `}
  ${props =>
    props.hide === 'body' &&
    css`
      .ant-table-tbody {
        display: none;
      }

      .ant-table,
      .ant-table-container {
        border-bottom: none;
      }

      .ant-table-thead > tr > th {
        border-bottom: none;
      }
    `}
  ${props =>
    props.extra === 'pagination' &&
    css`
      .ant-pagination-total-text {
        //float: left;
      }

      .ant-pagination-item {
        display: none;
        background-color: #f1eaff !important;
        border: none;

        a {
          color: #00aee6;
        }

        &:hover,
        &.ant-pagination-item-active {
          background-color: #00aee6 !important;

          a {
            color: #fff;
          }
        }
      }

      .ant-pagination-item-ellipsis,
      .ant-pagination-item-link-icon {
        color: #00aee6 !important;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        // padding-right: 30px;
        button {
          background-color: transparent;
          border-radius: 50%;
          border: none;
          color: rgba(0, 0, 0, 0.25);
        }

        &.ant-pagination-disabled,
        &.ant-pagination-disabled:hover {
          button {
            color: rgba(0, 0, 0, 0.25);
            border-color: #d9d9d9;
            background-color: transparent;
          }
        }

        &:hover {
          button {
            background-color: #00aee6;
            border-radius: 50%;
            border-color: #00aee6;
            color: #fff;
            width: 30px;
          }
        }
      }
    `}
  ${props =>
    props.pagination_margin &&
    css`
      .ant-pagination {
        margin: ${props.pagination_margin};
      }
    `}
  ${props =>
    props.pagination_padding &&
    css`
      .ant-pagination {
        padding: ${props.pagination_padding};
      }
    `}
  ${props =>
    props.rowHeight &&
    css`
      .ant-table-thead > tr {
        height: ${props.rowHeight}px;
      }
    `}
  ${props =>
    props.flex === 'contents' &&
    css`
      .ant-table-thead > tr {
        display: contents;
      }
    `}
  ${props =>
    props.odd_row_color &&
    css`
      .ant-table-tbody .ant-table-row {
        &:nth-child(odd) {
          background-color: ${props.odd_row_color};
        }
      }
    `}

  ${props =>
    props.double_exp_row_color &&
    css`
      .ant-table-tbody {
        .ant-table-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            background: #fff;
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            background: ${props.double_exp_row_color};
          }
        }
        .ant-table-expanded-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            td {
              background: ${props.double_exp_row_color};
            }
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            td {
              background: #fff;
            }
          }
        }
      }
    `}
  ${props =>
    props.flex === 'contents-none' &&
    css`
      .ant-table-thead > tr > .ant-table-cell {
        padding: 11px 16px;
      }
    `}
  ${props =>
    props.expand_pos &&
    css`
      .ant-table-row-expand-icon {
        float: ${props.expand_pos};
      }
    `}
  ${props =>
    props.append_cell_back &&
    css`
      .ant-table-row-level-0 > .ant-table-cell-with-append {
        background-color: ${props.append_cell_back};
      }
    `}
  ${props =>
    props.expanded_row_extras_display &&
    css`
      .ant-table-row-level-1 > .ant-table-cell-with-append {
        .ant-table-row-expand-icon,
        .ant-table-row-indent {
          display: ${props.expanded_row_extras_display};
        }
      }
    `}
  ${props =>
    props.head_fw &&
    css`
      .ant-table-thead > tr > th {
        font-weight: ${props.head_fw};
      }
    `}
`;

Table.propTypes = {
  type: propTypes.string,
};

Table.defaultProps = {};

/** @component */
export default Table;
