import styled, { css } from 'styled-components';
import { Input as input } from 'antd';

const Input = styled(input)`
  background: #fff;
  border: 1px solid #edeff1 !important;
  box-sizing: border-box;
  //border-radius: 50px;
  //height: 44px;
  height: 48px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    border-color: #edeff1 !important;
  }
  &:focus,
  &.ant-input-focused {
    border-color: #edeff1 !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #886cc02e;
  }

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #edeff1 !important;
  }
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080 !important;
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    
    ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top} !important;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.input_padding &&
    css`
      input {
        padding: ${props.input_padding}!important;
      }
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}

  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow}!important;
    `}
  ${props =>
    props.align &&
    css`
      text-align: ${props.align}!important;
    `}
    ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}

    ${props =>
    props.font_size &&
    css`
      font-size: ${props.font_size} !important;
    `}
    ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw} !important;
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
`;

export default Input;
