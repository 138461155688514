import Icon from '@ant-design/icons';

const BookingDocSVG = () => (
  <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.2001 0C1.3161 0 0.600098 0.716 0.600098 1.6V14.4C0.600098 15.284 1.3161 16 2.2001 16H8.6001C9.4841 16 10.2001 16.716 10.2001 17.6V1.6C10.2001 0.716 9.4841 0 8.6001 0H2.2001ZM13.4001 0C12.5161 0 11.8001 0.716 11.8001 1.6V17.6C11.8001 16.716 12.5161 16 13.4001 16H19.8001C20.6841 16 21.4001 15.284 21.4001 14.4V1.6C21.4001 0.716 20.6841 0 19.8001 0H13.4001Z'
      fill='#2C5C86'
    />
  </svg>
);

const Booking = props => <Icon component={BookingDocSVG} {...props} />;

export default Booking;
