export const colors = {
  main_grey: '#58585A',
  main_blue: '#2C5C86',
  main_blue_10: '#2C5C8610',
  main_light_blue: '#00AEE6',
  main_light_blue_10: '#00AEE610',
  main_light_blue_09: 'rgba(110, 220, 255, 0.09)',

  text_error: '#F93232',

  bright_gray: '#EDEFF1',
  icon_disabled: '#B5B5B5',
  icon_disabled_24: '#B5B5B524',

  sheen_green: '#91C500',
  ruddy: '#FF002E',
  chinese_gold: '#C5B100',

  status_colors: {
    dazzled_blue: '#2C5C86',
    vivid_cerulean: '#00AEE6',
    pewter_blue: '#B69083',
    sky_blue: '#6FDCFF',
    sheen_green: '#91C500',
    sheen_green_2: '#98DE03',
    blueberry: '#4787F3',
    ruddy: '#FF002E',
    pewter_blue_2: '#83A9B6',
    sea_green: '#09F5CB',
  },
};
