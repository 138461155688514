import React from 'react';
import { Link, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Title } from 'components/atoms';
import { colors } from 'Utils/colors/colors';
import { roleDefPaths } from '../constants/routes';
import { getUserRole } from '../app/services/selectorHelpers';
import BackNotFound from '../assets/images/backNotFound.png';

const NotFoundPage = () => {
  const { pathname } = useLocation();
  const role = useSelector(getUserRole);

  const defPath = role ? roleDefPaths[role] : '/sign-in';

  if (pathname === '/') {
    return <Navigate to={defPath} />;
  }
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        backgroundImage: `url(${BackNotFound})`,
        backgroundPosition: 'bottom',
        // backgroundSize: 'cover',
        backgroundSize: '100% 67%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Row
        gutter={24}
        align='center'
        justify='center'
        padding='60px 60px'
        flex_direction='column'
        type='not-found'
      >
        <Col>
          <Title fw='900' fz='120px' color={colors.main_light_blue} mb='32px' align='center'>
            404
          </Title>
        </Col>
        <Col>
          <Title fw='700' fz='56px' color={colors.main_grey} mb='16px' align='center'>
            OOPS!
          </Title>
        </Col>
        <Col>
          <Title fw='400' fz='32px' color={colors.main_grey} mb='32px' align='center'>
            Page not found
          </Title>
        </Col>
        <Link to={defPath}>
          <Button height='48px' radius='8px' type='primary' width='100%'>
            Go back
          </Button>
        </Link>
      </Row>
    </div>
  );
};
export default NotFoundPage;
