import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openNotificationWithIcon } from 'components/molecules/notifications';
import { Button, Col, Form, FormItem, Input, InputPassword, Row, Link } from '../../atoms';

import { useLoginMutation } from '../../../app/services/auth';
import { setUser } from '../../../app/slices/user';
import { roleDefPaths } from '../../../constants/routes';

const layout = {
  layout: 'vertical',
  wrapperCol: {
    span: 24,
  },
};

const SignIn = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const handleLogin = values => {
    login({ identifier: values.identifier, password: values.password, strategy: 'local' })
      .then(res => {
        if (res?.data) {
          dispatch(setUser({ jwt: res?.data?.jwt, user: res?.data?.user }));
          navigate(roleDefPaths[res?.data?.user?.role?.type] || '/dashboard');
        } else {
          // eslint-disable-next-line no-lonely-if
          if (res?.error?.status === 400) {
            openNotificationWithIcon('error', 'the user name or password is incorrect');
          } else {
            openNotificationWithIcon('error', 'Something went wrong');
          }
        }
      })
      .catch(() => {});
  };

  return (
    <Form type='sign-in' {...layout} form={form} width='100%' onFinish={handleLogin}>
      <Row>
        <Col span={24}>
          <FormItem
            name='identifier'
            label='Email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input Email!',
              },
            ]}
          >
            <Input placeholder='Email*' />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name='password'
            label='Password'
            rules={[
              {
                required: true,
                message: 'Please input Password!',
              },
            ]}
          >
            <InputPassword placeholder='Password*' />
          </FormItem>
        </Col>
        <Col justify='end' span={24}>
          <Link to='/forget-password'>
            <Button mb='26' type='signIn_ghost'>
              Forgot Password
            </Button>
          </Link>
        </Col>
        <Col span={24}>
          <FormItem mb={15}>
            <Button radius='8px' type='primary' htmlType='submit' width='100%' loading={isLoading}>
              Log In
            </Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default SignIn;
