import Icon from '@ant-design/icons';

const ReviewSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M22 6.08601C22 5.53133 21.5542 5.07929 20.9996 5.07169C15.7737 5 12.1995 5 12 5H3.6C2.716 5 2 5.716 2 6.6V16.886C2 17.77 2.716 18.486 3.6 18.486H20.4C21.284 18.486 22 17.77 22 16.886V6.68601V6.08601ZM16 9.6H12.3C11.8584 9.6 11.5 9.2416 11.5 8.8C11.5 8.3584 11.8584 8 12.3 8H16C16.4416 8 16.8 8.3584 16.8 8.8C16.8 9.2416 16.4416 9.6 16 9.6ZM18 12.6H12.3C11.8584 12.6 11.5 12.2416 11.5 11.8C11.5 11.3584 11.8584 11 12.3 11H18C18.4416 11 18.8 11.3584 18.8 11.8C18.8 12.2416 18.4416 12.6 18 12.6ZM7.5 8L8.5815 10.3039L11 10.6756L9.25 12.4679L9.663 15L7.5 13.8039L5.337 15L5.75 12.4679L4 10.6756L6.4185 10.3039L7.5 8ZM12.3 14C11.8582 14 11.5 14.3582 11.5 14.8C11.5 15.2418 11.8582 15.6 12.3 15.6H19.7C20.1418 15.6 20.5 15.2418 20.5 14.8C20.5 14.3582 20.1418 14 19.7 14H12.3Z'
      fill='#2C5C86'
    />
    <path d='M17 20L15.2679 18.5H18.7321L17 20Z' fill='#2C5C86' />
  </svg>
);

const ReviewIcon = props => <Icon component={ReviewSVG} {...props} />;

export default ReviewIcon;
