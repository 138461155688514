import styled, { css } from 'styled-components';
import { Form as form } from 'antd';

const Form = styled(form)`
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
    ${props =>
    props.type === 'sign-in' &&
    css`
      height: 25%;
      background: #ffffff;
      box-shadow: 2px 2px 4px rgba(0, 19, 36, 0.5);
      border-radius: 16px;
      padding: 9.3%;
      z-index: 1;
      & .ant-form-item-required {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #58585a;
        width: fit-content !important;
        height: fit-content !important;
        position: relative;
        &::before {
          content: '*';
          display: block !important;
          position: absolute;
          right: -16px;
          top: 0;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #58585a;
        }
      }

      & input {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        box-shadow: 2px 2px 4px rgba(44, 92, 134, 0.1);
        height: 48px;
        outline: none;
      }
      & .ant-input-password {
        box-shadow: 2px 2px 4px rgba(44, 92, 134, 0.1);
        height: 48px;
        & input {
          box-shadow: none !important;
          height: 100% !important;
        }
      }
      & .ant-form-item-explain {
        height: fit-content !important;
      }
      & .ant-form-item-explain-error {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #ff002e;
      }
      & .ant-form-item-has-error {
        & .ant-form-item-control-input {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: #ff002e;
            height: 1px;
            width: 90%;
          }
        }
      }
    `}
`;

export default Form;
