import React from 'react';
import styled, { css } from 'styled-components';

const ModalWrapperComp = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: #58585a66;
  overflow-y: auto;
  backdrop-filter: blur(10px);
  align-items: center;

  ${props =>
    !props.zIndex
      ? css`
          z-index: 1000;
        `
      : css`
          z-index: ${props.zIndex};
        `};
`;

const ModalWrapper = ({ children, closeModal, zIndex }) => {
  return (
    <ModalWrapperComp zIndex={zIndex} onClick={closeModal}>
      {children}
    </ModalWrapperComp>
  );
};

export default ModalWrapper;
