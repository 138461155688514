import styled, { css } from 'styled-components';

const DashboardItemsWrapper = styled.div`
  box-shadow: 2px 2px 4px rgba(44, 92, 134, 0.1);
  padding: 24px 24px 8px 24px;

  ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt}!important;
    `}

  ${props =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}!important;
    `}

    ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}

    ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
`;

export default DashboardItemsWrapper;
