import styled, { css } from 'styled-components';
import { Input } from 'antd';

const TextArea = styled(Input.TextArea)`
  background: #ffffff;
  border: 1px solid #eeeeee;
  //border-right: none;
  box-sizing: border-box;
  //border-radius: 20px;
  //height: 44px;
  padding: 15px;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  resize: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    border-color: #00aee6;
  }
  &:focus,
  &.ant-input-focused {
    border-color: #00aee6;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #886cc02e;
  }
  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080 !important;
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height}!important;
    `}
  ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height}!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
    ${props =>
    props.font_size &&
    css`
      font-size: ${props.font_size} !important;
    `}
    ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw} !important;
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
`;

export default TextArea;
